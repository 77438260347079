import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Layout, StyledUl, DropdownHeading,  MenuItem, CheckMarkContainer, MenuItemWrappper } from './style';
import { ImageUrlNew } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
class StyledDropDown extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true,
      title: props.title
    }
  }

  showMenuItems = () => {

    this.setState((prev) => ({
      open: !prev.open
    }));
  };

  componentDidMount() {
    if (this.props.isAdventurePage) {
      const stringArray = this.props.location.pathname.split('/');
      if (stringArray[stringArray.length - 1] !== 'videos-library' &&
        stringArray[stringArray.length - 1] !== 'adventure' &&
        stringArray[stringArray.length - 1] !== 'webinar' &&
        stringArray[stringArray.length - 1] !== 'recipes'
      ) {
        let dropdownTitle = this.props.location.pathname.includes('recipes') ? this.props.location.pathname.includes('category') ? stringArray[stringArray.length - 1] : 'Recipe Categories' : stringArray[stringArray.length - 1];
        this.setState({
          title: dropdownTitle
        })
      } else {
        this.setState({
          title: this.props.title
        });
      }
    }
    document.addEventListener('click', this.closeDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropDown);
  }

  closeDropDown = (event) => {
    if ((this.wrapperRef && !this.wrapperRef.contains(event.target)) && this.state.isOpen) {
      this.setState({
        isOpen: false
      })
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.isAdventurePage) {
      if (prevProps.location !== this.props.location || this.props.title !== prevProps.title) {
        const stringArray = this.props.location.pathname.split('/');
        if (stringArray[stringArray.length - 1] !== 'videos-library' &&
          stringArray[stringArray.length - 1] !== 'adventure' &&
          stringArray[stringArray.length - 1] !== 'webinar' &&
          stringArray[stringArray.length - 1] !== 'recipes'
        ) {
          let dropdownTitle = this.props.location.pathname.includes('recipes') ? this.props.location.pathname.includes('category') ? stringArray[stringArray.length - 1] : 'Recipe Categories' : stringArray[stringArray.length - 1];
          this.setState({
            title: dropdownTitle
          })
        } else {
          this.setState({
            title: this.props.title
          });
        }
      }
    }
  }

  selectMenuItem = (item) => {
    const { history } = this.props;
    this.setState({
      title: item.recipe_tag,
      isOpen: false
    });
    history.push(`/education/recipes/category/${item.recipe_tag}`);
  };

  render() {
    const { title, open } = this.state;
    const { MenuItems, isCompanyDashboard,  isOpen } = this.props;

    return (
      isOpen && open && (
        <Layout isOpen={isOpen} isCompanyDashboard={isCompanyDashboard} >
          <div ref={(node) => this.wrapperRef = node}>
            <div>
              {

                <StyledUl>
                  <DropdownHeading>
                    <div className={isCompanyDashboard ? "companyname" : "title"}>{this.props.t("Recipe")}</div>
                    <div>
                      <img src="/public/images/NewDashboardV2/closedropdown.png" alt="back-arrow" onClick={this.showMenuItems} />
                    </div>
                  </DropdownHeading>
                  <MenuItemWrappper>
                    {
                      MenuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => this.selectMenuItem(item)}
                          isActive={item.recipe_tag}
                          color={item.recipe_tag.toLowerCase() == title.toLowerCase()}
                        >
                          <CheckMarkContainer>
                            <img src={`${ImageUrlNew}/${item.image}`} />
                          </CheckMarkContainer>
                          {this.props.t(item.recipe_tag)}
                        </MenuItem>
                      ))
                    }
                  </MenuItemWrappper>
                </StyledUl>
              }
            </div>
          </div>
        </Layout>)
    )
  }
}

StyledDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  MenuItems: PropTypes.array.isRequired,
  isCompanyDashboard: PropTypes.bool,
  isAdventurePage: PropTypes.bool,
  eventKey: PropTypes.number,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  isReports: PropTypes.bool,
  isOpen: PropTypes.bool,
  t: PropTypes.func
};

export default withRouter((withTranslation())(StyledDropDown));